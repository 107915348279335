import { useInputControl } from '@conform-to/react'
import {
	Checkbox as CheckboxPrimitive,
	type CheckboxProps as RadixCheckboxProps,
} from '@radix-ui/themes'
import { useId } from 'react'
import { ErrorList, type ListOfErrors } from '../../forms'
import { Label } from '../label'

const Checkbox = CheckboxPrimitive

function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements['label']
	buttonProps: RadixCheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()

	const checkedValue = buttonProps.value ?? 'on'

	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="my-2 flex items-center gap-2">
				<Checkbox
					{...checkboxProps}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={state => {
						input.change(state ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={event => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={event => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
					// Remove aria-hidden from the hidden input element
					data-hidden-input={{ 'aria-hidden': false }}
					aria-hidden={false}
				/>
				<Label
					{...labelProps}
					className="cursor-pointer self-center text-body-xs text-muted-foreground"
				/>
			</div>
			{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
		</div>
	)
}

export { Checkbox, CheckboxField }
